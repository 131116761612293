import { arraySwap } from '@dnd-kit/sortable';
import { message } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { api } from '../../../utils';
import type { CandidateMatchGmsGoods, CandidateMatchPosGoodsList, IndexParams, MatchList, SubmitMatchSKU } from './interface';

export default class MatchSkuModel {
  constructor(options?: { parent: unknown; }) {
    this.parent = options ? options.parent : null;
  }

  private parent = null;

  @observable public visible = false;

  @observable public loading = false;

  @observable public gmsGoodsId = null; // 被选中的店铺款式id列表

  @observable public posGoodsIdList: number[] = []; // 衫海精商品id

  @observable public gmsGoods: CandidateMatchGmsGoods; // 店铺款式列表

  @observable public posGoodsList: CandidateMatchPosGoodsList[] = []; // 待关联的衫海精商品列表

  @action public onShow = (gmsGoodsId: number, posGoodsIdList: number[]): void => {
    this.visible = true;
    this.gmsGoodsId = gmsGoodsId;
    this.posGoodsIdList = posGoodsIdList;
    this.getMatchList();
  };

  @action public onClose = (): void => {
    this.visible = false;
    this.gmsGoodsId = null;
    this.posGoodsIdList = [];

    this.gmsGoods = null;
    this.posGoodsList = [];
  };

  @action private getMatchList = async(): Promise<void> => {
    const res: BaseData<MatchList> = await request({
      url: api.getSkuMatchList,
      method: 'post',
      data: {
        gmsGoodsId: this.gmsGoodsId,
        posGoodsIdList: this.posGoodsIdList,
      },
    });

    const { candidateMatchGmsGoods, candidateMatchPosGoodsList } = res.data;
    this.gmsGoods = candidateMatchGmsGoods;
    this.posGoodsList = candidateMatchPosGoodsList;
  };

  // size列表内部排序
  @action public onSkuSortEnd = ({ oldIndex, newIndex }: IndexParams, color: string): void => {
    const index = this.gmsGoods.candidateMatchGmsSkuList.findIndex((item) => item.color === color);
    if (index === -1) {
      return;
    }
    const oldList = this.gmsGoods.candidateMatchGmsSkuList[index]?.posColor?.skuList;
    if (oldList) {
      const newPosColor = arraySwap(oldList, oldIndex, newIndex);
      this.gmsGoods.candidateMatchGmsSkuList[index].posColor = {
        ...this.gmsGoods.candidateMatchGmsSkuList[index].posColor,
        skuList: newPosColor,
      };
    }
  };

  // 拖拽sku
  @action public onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) { // 未拖到指定位置
      return;
    }
    if (source.droppableId === destination.droppableId) { // 未拖到指定位置
      return;
    }
    if (!destination.droppableId.includes('gms-')) { // 未拖到指定位置
      return;
    }
    if (!source.droppableId.includes('pos-list-')) { // 未拖到指定位置
      return;
    }

    const color = destination.droppableId.split('gms-')[1];
    const index = this.gmsGoods.candidateMatchGmsSkuList.findIndex((item) => item.color === color);
    if (index === -1) {
      return;
    }
    const posGoodsId = Number(source.droppableId.split('pos-list-')[1]);
    const posColor = result.draggableId.split(`pos-sku-${posGoodsId}-`)[1];
    const { posIndex, posSkuIdx } = this.getPosGoodsIdx(posGoodsId, posColor);
    if (posIndex === -1 || posSkuIdx === -1) {
      return;
    }
    const posGoodsColor = this.posGoodsList[posIndex].candidateMatchPosSkuColorGroup[posSkuIdx];
    const gmsSizeSet = this.gmsGoods.candidateMatchGmsSkuList[index].sizeSet;
    const posGoodsSizeListLength = posGoodsColor?.skuList?.length;
    const gmsGoodsSizeListLength = gmsSizeSet.length;
    const length = gmsGoodsSizeListLength - posGoodsSizeListLength;
    let skuList = posGoodsColor?.skuList?.concat();

    // 补齐
    if (length > 0) {
      for (let i = 0; i < length; i++) {
        skuList.push({
          color: posGoodsColor.color,
          size: '--',
          posGoodsSkuId: i + 1,
        });
      }
    }

    // 自动按同尺码匹配一次
    for (let i = 0; i < gmsGoodsSizeListLength; i++) {
      for (let j = 0; j < posGoodsSizeListLength; j++) {
        if (gmsSizeSet[i] === skuList[j].size && i !== j) {
          skuList = arraySwap(skuList, i, j);
          break;
        }
      }
    }

    this.gmsGoods.candidateMatchGmsSkuList[index].posColor = {
      ...posGoodsColor,
      posGoodsId,
      skuList,
    };
  };

  private getPosGoodsIdx = (posGoodsId: number, color: string) => {
    const index = this.posGoodsList.findIndex((item) => item.posGoodsId === posGoodsId);
    if (index === -1) {
      return {
        posIndex: -1,
        posSkuIdx: -1,
      };
    }
    const skuIdx = this.posGoodsList[index].candidateMatchPosSkuColorGroup.findIndex((item) => item.color === color);
    if (skuIdx === -1) {
      return {
        posIndex: index,
        posSkuIdx: -1,
      };
    }
    return {
      posIndex: index,
      posSkuIdx: skuIdx,
    };
  };

  // 删除已匹配
  @action public deletePosColor = (color: string) => {
    const index = this.gmsGoods.candidateMatchGmsSkuList.findIndex((item) => item.color === color);
    if (index > -1) {
      this.gmsGoods.candidateMatchGmsSkuList[index].posColor = null;
    }
  };

  // 提交
  @action public onSubmit = async(): Promise<void> => {
    const goodsSkuMatchRequestList = []; // 关联结果
    this.gmsGoods.candidateMatchGmsSkuList.forEach((item) => {
      const { color, sizeSet, posColor } = item;
      sizeSet.forEach((size, index) => {
        const sku: SubmitMatchSKU = { color };
        if (posColor && posColor.skuList && posColor.skuList[index] && posColor.skuList[index].size !== '--') {
          sku.posGoodsSkuId = posColor.skuList[index].posGoodsSkuId;
          sku.posGoodsId = posColor.posGoodsId;
          sku.size = size;
          goodsSkuMatchRequestList.push(sku);
        }
      });
    });
    const finallyList = goodsSkuMatchRequestList.filter((item) => item.posGoodsSkuId);
    if (finallyList.length === 0) {
      message.warning('请先关联!');
      return;
    }
    try {
      this.loading = true;
      await request({
        url: api.gmsGoodsMatch,
        method: 'POST',
        data: {
          gmsGoodsId: this.gmsGoodsId,
          goodsSkuMatchRequestList: finallyList,
        },
      });
      message.success('关联成功!');
      this.onClose();
      window.top.egenie.closeTab('matchGoods');
    } finally {
      this.loading = false;
    }
  };
}

