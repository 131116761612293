import type { CSSProperties } from 'react';
import React from 'react';
import noPic from '../../../../assets/images/noPic.png';
import styles from './index.less';

interface InfoProps {
  style?: CSSProperties;
  type: string;
  goodsCode: string;
  mainPicUrl: string;
  goodsName: string;
  goodsCategoryName?: string;
  vendorShopName?: string;
}

const GoodsInfo = (props: InfoProps) => {
  const { style, type, goodsCode, mainPicUrl, goodsName, goodsCategoryName, vendorShopName } = props;
  return (
    <div
      className={styles.goodsInfo}
      style={type === 'pos' ? style : {}}
    >
      <div className={styles.goodsCode}>
        {type === 'pos' ? '衫海精款式编码' : '店铺款式编码'}
        ：
        {goodsCode}
      </div>
      <div className={styles.mainInfo}>
        <div className={styles.picUrlWrapper}>
          <img src={mainPicUrl || noPic}/>
        </div>
        <div className={styles.otherInfo}>
          <div
            className={styles.name}
            style={{ maxWidth: type === 'pos' ? 300 : 500 }}
          >
            {goodsName}
          </div>
          <div className={styles.categoryWrapper}>
            <span
              className={styles.goodsCategoryName}
              style={{ maxWidth: type === 'pos' ? 100 : 500 }}
            >
              {goodsCategoryName}
            </span>
            {type === 'pos' && (
              <span className={styles.vendorShopName}>
                店铺：
                {vendorShopName}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { GoodsInfo };
