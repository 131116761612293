import { Spin } from 'antd';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React from 'react';
import noPic from '../../assets/images/noPic.png';
import { PRODUCT_LINK } from '../myStyle/constant';
import styles from './index.less';
import type MatchGoodsStore from './store';

interface Props {
  store: MatchGoodsStore;
}

@observer
class GmsStyleList extends React.Component<Props> {
  private renderStyleList = () => {
    const { styleList } = this.props.store;
    return (
      <div
        className={styles.gmsStyleContent}
      >
        {styleList.map((el) => {
          return (
            <div
              className={styles.styleItem}
              key={el.gmsGoodsId}
            >
              <div className={styles.imgWrapper}>
                <img src={el.mainPicUrl || noPic}/>
              </div>
              <div className={styles.info}>
                <div
                  className={styles.goodsName}
                  onClick={
                    () => {
                      // 供销平台暂时不作跳转
                      if (el.platformType !== 32) {
                        window.top.open(PRODUCT_LINK[el.platformType](el.platformId));
                      }
                    }
                  }
                  title={el.goodsName}
                >
                  <span className={styles.goodsNameText}>
                    {el.goodsName}
                  </span>
                  {el.platformType !== 32 ? <i className="icon-sq"/> : null}
                </div>
                <div className={styles.goodsInfo}>
                  {el.goodsCategoryName}
                </div>
                <div
                  className={styles.goodsInfo}
                  title={el.goodsCode}
                >
                  店铺款式编码：
                  {el.goodsCode}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  render(): ReactNode {
    const { styleLoading } = this.props.store;
    return (
      <div className={styles.gmsStyle}>
        <div className={styles.title}>
          店铺款式
        </div>
        <Spin
          size="large"
          spinning={styleLoading}
        >
          {this.renderStyleList()}
        </Spin>
      </div>
    );
  }
}

export { GmsStyleList };
