import { message } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import type { PaginationData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { api } from '../../utils';
import MatchSkuModel from '../components/matchSku/store';
import type { Style, VendorGoods } from './interface';

export default class MatchGoodsStore {
  constructor() {
    this.initData();
  }

  @observable public styleLoading = false; // 店铺款式loading

  @observable public vendorLoading = false; // 平台款式ID列表loading

  @observable public gmsGoodsIdList: number[]; // 平台款式ID列表

  @observable public type = 1; // 1: 店铺商品管理； 2: 店铺订单

  @observable public styleList: Style[] = []; // 款式列表

  @observable public vendorGoodsList: VendorGoods[] = []; // 档口款式列表

  @observable private vendorGoodsPage = 1; // 档口款式列表分页

  @observable private vendorGoodsTotal = 1; // 档口款式列表分页

  @observable public selectedStyleList: VendorGoods[] = []; // 被选中的店铺款式id列表

  @observable public open = false; // 被选中的店铺款式popover 显隐

  @observable public showMatchSku = false; // 关联sku弹窗

  public vendorFormRef: FormInstance;

  public matchSkuModel = new MatchSkuModel({ parent: this });

  @action public setVendorRef = (form: FormInstance): void => {
    this.vendorFormRef = form;
  };

  @action public initData = (): void => {
    const params = location.href.split('?')[1];
    const search = new URLSearchParams(params);
    const gmsGoodsIdList = search.get('gmsGoodsIdList');
    const type = search.get('type');
    this.gmsGoodsIdList = JSON.parse(gmsGoodsIdList);
    this.type = Number(type);

    this.queryStyleList();
  };

  // 搜索店铺款式
  @action public queryStyleList = async(): Promise<void> => {
    this.styleLoading = true;
    const params = {
      page: 1,
      pageSize: 20,
    };
    try {
      const res: PaginationData<Style> = await request({
        url: api.pageWaitMatchList,
        method: 'post',
        data: this.type === 1 ? {
          ...params,
          gmsGoodsIdList: this.gmsGoodsIdList,
        } : {
          ...params,
          platformId: this.gmsGoodsIdList.length > 0 ? this.gmsGoodsIdList[0] : '',
        },
      });
      this.styleList = res.data.list;
    } finally {
      this.styleLoading = false;
    }
  };

  // 搜索档口款式
  @action public queryVendorGoods = async(page?: number): Promise<void> => {
    this.vendorLoading = true;
    try {
      const values = await this.vendorFormRef.validateFields();
      if (!values.goodsName && !values.goodsNo && !values.vendorId) {
        message.warning('请先填写查询条件');
        return;
      }
      const res: PaginationData<VendorGoods> = await request({
        url: api.getVendorGoodsList,
        method: 'post',
        data: {
          page: page ? page : this.vendorGoodsPage,
          pageSize: 20,
          ...values,
        },
      });
      this.vendorGoodsList = page && page === 1 ? res.data.list : this.vendorGoodsList.concat(res.data.list);
      this.vendorGoodsTotal = res.data.totalPageCount;
    } finally {
      this.vendorLoading = false;
    }
  };

  // 档口款式分页
  @action public loadMoreVendorGoods = (): void => {
    if (this.vendorGoodsPage < this.vendorGoodsTotal) {
      this.vendorGoodsPage += 1;
      this.queryVendorGoods();
    }
  };

  @action public showMatchSkuModal = (goodsId?: number): void => {
    const gmsGoodsId = this.styleList?.length > 0 ? this.styleList[0].gmsGoodsId : null;
    const posGoodsIdList = goodsId ? [goodsId] : this.selectedStyleList?.map((item) => item.goodsId);
    this.open = false;
    this.matchSkuModel.onShow(gmsGoodsId, posGoodsIdList);
  };

  // 勾选/取消勾选某一店铺款式
  @action public onSelectStyle = (vendorStyle: VendorGoods, checked: boolean): void => {
    if (checked) {
      this.selectedStyleList.push(vendorStyle);
    } else {
      this.selectedStyleList = this.selectedStyleList.filter((item) => item.goodsId !== vendorStyle.goodsId);
    }
  };

  @action public handleOpenChange = (newOpen: boolean): void => {
    this.open = newOpen;
  };
}

