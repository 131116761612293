import { DndContext } from '@dnd-kit/core';
import { rectSwappingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { PUBLIC_IMAGE_URL } from '../../../../utils';
import type { Color, IndexParams, SKU } from '../interface';
import styles from './index.less';

interface SkuListProps {
  type: string;
  color: string;
  pic?: string;
  sizeList: string[] | SKU[];
  posColor?: Color;
  onSkuSortEnd?: ({ oldIndex, newIndex }: IndexParams, color: string) => void;
  deletePosColor?: (color: string) => void;
}

const SkuList = (props: SkuListProps) => {
  const { type, color, pic, sizeList, posColor, onSkuSortEnd, deletePosColor } = props;

  return (
    <div
      className={styles.skuList}
      id={color}
    >
      <ColorItem
        color={color}
        pic={pic}
        showIcon={type === 'pos'}
      />
      <div className={styles.sizeList}>
        {sizeList?.map((item) => {
          return (
            <div
              className={`${styles.item} ${styles.size}`}
              key={type === 'pos' ? item.posGoodsSkuId : item}
            >
              {type === 'pos' && <i className={`icon-note_td ${styles.skuIcon}`}/>}
              {type === 'pos' ? item.size : item}
            </div>
          );
        })}
      </div>
      {type === 'gms' && (
        <>
          <div>
            <img
              className={styles.linkIcon}
              src={`${PUBLIC_IMAGE_URL}link.png`}
            />
          </div>
          <div
            className={styles.target}
          >
            {posColor ? (
              <div className={styles.skuList}>
                <ColorItem
                  color={posColor.color}
                  pic={posColor.colorPic}
                  showIcon
                />
                <div className={styles.sizeList}>
                  <SortableList
                    onSortEnd={(idxParams) => onSkuSortEnd && onSkuSortEnd(idxParams, color)}
                    posColor={posColor}
                  />
                </div>
                <i
                  className={`icon-delete ${styles.deleteIcon}`}
                  onClick={() => deletePosColor(color)}
                />
              </div>
            ) : (
              <Droppable droppableId={`gms-${color}`}>
                {(provided, snapshot) => (
                  <div
                    className={styles.dropWrapper}
                    ref={provided.innerRef}
                  >
                    <span className={styles.tips}>
                      请将衫海精商品拖入匹配
                    </span>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const ColorItem = (props: { showIcon?: boolean; color?: string; pic?: string; }) => {
  const { showIcon, color, pic } = props;
  return (
    <div
      className={styles.item}
      style={{ marginRight: 8 }}
    >
      {showIcon && <i className={`icon-note_td ${styles.skuIcon}`}/>}
      {color}
      {Boolean(pic) && (
        <img
          className={styles.pic}
          src={pic}
        />
      )}
    </div>
  );
};

const SortableList = (props: { posColor: Color; onSortEnd: ({ oldIndex, newIndex }: IndexParams) => void; }) => {
  const { posColor, onSortEnd } = props;
  const onDragEnd = (e) => {
    const { active, over } = e;

    if (active.id !== over?.id) {
      const oldIndex = posColor.skuList.findIndex((item) => item.posGoodsSkuId === active.id);
      const newIndex = posColor.skuList.findIndex((item) => item.posGoodsSkuId === over.id);
      onSortEnd({
        oldIndex,
        newIndex,
      });
    }
  };

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        items={posColor.skuList.map((sku) => sku.posGoodsSkuId)}
        strategy={rectSwappingStrategy}
      >
        {posColor.skuList.map((el) => (
          <SortableItem
            el={el}
            key={el.posGoodsSkuId}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

const SortableItem = ({ el }: { el: SKU; }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: el.posGoodsSkuId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className={`${styles.item} ${styles.size}`}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <i className={`icon-note_td ${styles.skuIcon}`}/>
      {el.size}
    </div>
  );
};

export { SkuList };
