import { Button, Checkbox, Popover, Spin } from 'antd';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React from 'react';
import noPic from '../../assets/images/noPic.png';
import { VendorGoodsForm } from '../components';
import styles from './index.less';
import type MatchGoodsStore from './store';

interface Props {
  store: MatchGoodsStore;
}

@observer
class VendorStyleList extends React.Component<Props> {
  private vendorGoodsRef = null;

  private handleScroll = (): void => {
    if (this.vendorGoodsRef.scrollTop + this.vendorGoodsRef.clientHeight >= this.vendorGoodsRef.scrollHeight) {
      this.props.store.loadMoreVendorGoods();
    }
  };

  private setVendorGoodsRef = (ref) => {
    this.vendorGoodsRef = ref;
  };

  private renderList = (type?: string) => {
    const { selectedStyleList, vendorGoodsList, showMatchSkuModal, onSelectStyle } = this.props.store;
    const list = type === 'selected' ? selectedStyleList : vendorGoodsList;
    return list?.map((el) => {
      const checked = Boolean(selectedStyleList.find((item) => item.goodsId === el.goodsId));
      return (
        <div
          className={styles.vendorStyleItem}
          key={el.goodsId}
        >
          <div className={styles.wrapper}>
            <Checkbox
              checked={checked}
              onChange={(e) => onSelectStyle(el, e.target.checked)}
            />
            <div className={styles.imgWrapper}>
              <img src={el.mainPicUrl || noPic}/>
            </div>
            <div
              className={styles.info}
            >
              <div
                className={styles.goodsName}
                onClick={() => {
                  window.open(`/egenie-ts-vogue/goodsDetail/index?goodsId=${el.goodsId}`);
                }}
                style={{ maxWidth: 374 }}
                title={el.goodsName}
              >
                <span className={styles.goodsNameText}>
                  {el.goodsName}
                </span>
                <i className="icon-sq"/>
              </div>
              <div className={styles.goodsInfo}>
                <span>
                  {el.goodsCategoryName}
                </span>
                <span
                  className={styles.vendorShopName}
                  title={el.vendorShopName}
                >
                  {el.vendorShopName}
                </span>
              </div>
              <div
                className={styles.goodsInfo}
                title={el.sellerOuterNo}
              >
                衫海精款式货号：
                {el.sellerOuterNo}
              </div>
            </div>
          </div>
          {!type && (
            <Button
              className={styles.queryBtn}
              onClick={() => showMatchSkuModal(el.goodsId)}
              type="primary"
            >
              关联
            </Button>
          )}
        </div>
      );
    });
  };

  private renderTotal = () => {
    const { open, selectedStyleList, showMatchSkuModal, handleOpenChange } = this.props.store;
    const content = (
      <div className={styles.popover}>
        {this.renderList('selected')}
        <div className={styles.footer}>
          <Button
            className={styles.cancelBtn}
            onClick={() => {
              handleOpenChange(false);
            }}
          >
            取消
          </Button>
          <Button
            onClick={() => showMatchSkuModal()}
            type="primary"
          >
            关联
          </Button>
        </div>
      </div>
    );
    const totalNode = (
      <div className={styles.total}>
        <div>
          {selectedStyleList.length}
        </div>
        <div className={styles.text}>
          已选
        </div>
      </div>
    );
    return (
      selectedStyleList.length > 0 ? (
        <Popover
          content={content}
          onVisibleChange={handleOpenChange}
          placement="topLeft"
          trigger="click"
          visible={open}
        >
          {totalNode}
        </Popover>
      ) : totalNode
    );
  };

  render(): ReactNode {
    const { vendorLoading, setVendorRef, queryVendorGoods } = this.props.store;

    return (
      <div className={styles.vendorStyle}>
        <div className={styles.title}>
          衫海精商品
        </div>
        <Spin
          size="large"
          spinning={vendorLoading}
        >
          <div className={styles.header}>
            <VendorGoodsForm
              onSearch={() => queryVendorGoods(1)}
              setVendorRef={setVendorRef}
            />
          </div>
          <div className={styles.tips}>
            如店铺商品为拼款，可勾选多个衫海精款式进行关联
          </div>
          <div
            className={styles.vendorStyleContent}
            onScroll={this.handleScroll}
            ref={(ref) => this.setVendorGoodsRef(ref)}
          >
            {this.renderList()}
          </div>
          {this.renderTotal()}
        </Spin>
      </div>
    );
  }
}

export { VendorStyleList };
