import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { MatchSku } from '../components/index';
import { GmsStyleList } from './gmsStyleList';
import styles from './index.less';
import MatchGoodsStore from './store';
import { VendorStyleList } from './vendorStyleList';

// 关联sku
const MatchGoods = observer(() => {
  const store = useMemo(() => new MatchGoodsStore(), []);
  const { matchSkuModel } = store;
  return (
    <div className={styles.page}>
      <div className={styles.matchGoods}>
        <GmsStyleList store={store}/>
        <VendorStyleList store={store}/>
      </div>
      <MatchSku
        store={matchSkuModel}
      />
    </div>
  );
});

export default MatchGoods;
