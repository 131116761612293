import { Button } from 'antd';
import { FullModal } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { GoodsInfo } from './goodsInfo';
import styles from './index.less';
import type { CandidateMatchGmsSkuList, CandidateMatchPosGoodsList } from './interface';
import { SkuList } from './skuList';
import type MatchSkuModel from './store';

interface Props {
  store: MatchSkuModel;
}

// 批量关联sku弹窗
@observer
class MatchSku extends React.Component<Props> {
  // 渲染左边gms商品
  private renderGmsGoods = () => {
    const { gmsGoods, deletePosColor, onSkuSortEnd } = this.props.store;
    return (
      <div className={styles.info}>
        <GoodsInfo
          goodsCategoryName={gmsGoods?.goodsCategoryName}
          goodsCode={gmsGoods?.goodsCode}
          goodsName={gmsGoods?.goodsName}
          mainPicUrl={gmsGoods?.mainPicUrl}
          type="gms"
        />
        <div className={styles.skuWrapper}>
          {gmsGoods?.candidateMatchGmsSkuList?.map((item: CandidateMatchGmsSkuList) => {
            return (
              <SkuList
                color={item.color}
                deletePosColor={deletePosColor}
                key={item.color}
                onSkuSortEnd={onSkuSortEnd}
                pic={item.picUrl}
                posColor={item.posColor}
                sizeList={item.sizeSet}
                type="gms"
              />
            );
          })}
        </div>
      </div>
    );
  };

  // 渲染右边pos列表
  private renderPosGoodsList = () => {
    const { posGoodsList } = this.props.store;

    return (
      <div className={styles.info}>
        {posGoodsList?.map((el: CandidateMatchPosGoodsList, index: number) => {
          return (
            <div key={el.posGoodsId}>
              <GoodsInfo
                goodsCategoryName={el.goodsCategoryName}
                goodsCode={el.goodsNo}
                goodsName={el.goodsName}
                mainPicUrl={el.mainPicUrl}
                style={{ marginTop: index === 0 ? 0 : 8 }}
                type="pos"
                vendorShopName={el.vendorShopName}
              />
              <Droppable droppableId={`pos-list-${el.posGoodsId}`}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                  >
                    {el.candidateMatchPosSkuColorGroup?.map((item) => {
                      return (
                        <Draggable
                          draggableId={`pos-sku-${el.posGoodsId}-${item.color}`}
                          index={index}
                          key={item.color}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <SkuList
                                color={item.color}
                                pic={item.colorPic}
                                sizeList={item.skuList}
                                type="pos"
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { visible, loading, onClose, onSubmit, onDragEnd } = this.props.store;

    const children = (
      <div className={styles.matchSku}>
        <div className={styles.skuTips}>
          <i className={`icon-home_tx ${styles.tipsIcon}`}/>
          <span className={styles.skuTipsText}>
            可拖拽衫海精商品与店铺商品匹配，尺码可上下拖动排序
          </span>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className={styles.content}>
            <div className={styles.gmsGoodsInfo}>
              <div className={styles.title}>
                店铺款式
              </div>
              {this.renderGmsGoods()}
            </div>
            <div className={styles.posGoodsInfo}>
              <div className={styles.title}>
                衫海精商品
              </div>
              <div>
                {this.renderPosGoodsList()}
              </div>
            </div>
          </div>
        </DragDropContext>
      </div>
    );

    return (
      <FullModal
        children={children}
        onCancel={onClose}
        operation={(
          <>
            <Button
              className={styles.cancelBtn}
              onClick={onClose}
            >
              取消
            </Button>
            <Button
              loading={loading}
              onClick={onSubmit}
              type="primary"
            >
              关联
            </Button>
          </>
        )}
        title="SKU关联"
        visible={visible}
      />

    );
  }
}

export { MatchSku };

